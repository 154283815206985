import React from "react"
import Layout from "../components/layout"
import Posts from "../components/Posts"
import { graphql, Link } from "gatsby"
import { css } from "@emotion/react"

export const query = graphql`
  query GetCategories($category: String, $skip: Int!, $limit: Int!) {
    categories: allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      filter: {frontmatter: {category: {eq: $category}}}
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          excerpt
          frontmatter {
            category
            date(formatString: "YYYY-MM-DD")
            title
          }
          fields {
            slug
          }
          id
        }
      }
    }
  }
`

const CategoryTemplate = props => {
  console.log(props)
  const posts = props.data.categories.edges
  const pageContext = props.pageContext

  return (
    <Layout>
      <h3 css={title}>
        <Link to={"/categories"}>Categories</Link>/ {pageContext.category}
      </h3>
      <Posts posts={posts} pageContext={pageContext} />
    </Layout>
  )
}

const title = css`
  font-weight: 700;
  text-transform: capitalize;
  color: var(--clr-grey-5);
  font-size: 1.25rem;
  margin-bottom: 2.5rem;
`

export default CategoryTemplate
